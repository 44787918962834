<template>
  <div id="overview">
    <Loader v-if="showIsLoading" />
    <div v-if="showDashboard">
      <div class="vx-row">
        <!-- CARD 1: CONGRATS -->
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <vx-card
            slot="no-body"
            class="text-center bg-primary-gradient greet-user"
          >
            <img
              src="@/application/assets/images/graphics/decore-left.png"
              class="decore-left"
              alt="Decore Left"
              width="200"
            />
            <img
              src="@/application/assets/images/graphics/decore-right.png"
              class="decore-right"
              alt="Decore Right"
              width="175"
            />
            <feather-icon
              icon="AwardIcon"
              class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
              svgClasses="h-8 w-8"
            ></feather-icon>
            <h1 class="mb-6 text-white">
              {{ $l.get("dictionary.congratulations") }}
              {{ data.session.user.name }},
            </h1>
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
              {{ $l.get("overview.unlock-percent-before") }}
              <strong>{{ reportsCountsSeries[0] }}%</strong>
              {{ $l.get("overview.unlock-percent-after") }}
            </p>
          </vx-card>
        </div>
        <div class="vx-col w-full lg:w-1/4 xl:w-1/4">
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("dictionary.raw-data", "capitalize") }}</h3>
            </div>
            <vs-row style="margin-top: 10px; margin-bottom: 20px;">
              <vs-col vs-w="6" style="text-align: center">
                <h3
                  style="text-align: center; margin-top: 13px; margin-bottom: 0px;"
                >
                  {{ formatNumber(data.dashboard.rawDataInfo.snpsCount) }}
                  SNPs
                </h3>
                <div style="font-size: 14px; margin-top: 20px;">
                  <span v-if="data.dashboard.rawDataInfo.sourceType == 1">
                    {{ $l.get("dictionary.source") }}:
                    <b>myheritage</b>
                  </span>
                  <span v-if="data.dashboard.rawDataInfo.sourceType == 2">
                    {{ $l.get("dictionary.source") }}:
                    <b>23andme</b>
                  </span>
                  <span v-if="data.dashboard.rawDataInfo.sourceType == 3">
                    {{ $l.get("dictionary.source") }}:
                    <b>ancestry</b>
                  </span>
                  <br />
                  <span>
                    Upload:
                    <b>{{ data.dashboard.rawDataInfo.createdAt }}</b>
                  </span>

                  <br />
                  <span>
                    {{ $l.get("dictionary.format") }}:
                    <b>{{ data.dashboard.rawDataInfo.extensionType }}</b>
                  </span>
                </div>
              </vs-col>
              <vs-col vs-w="6">
                <div style="margin-top: 7px; margin-right: 5px">
                  <div style="margin-bottom: 15px">
                    <vs-button
                      disabled
                      icon-pack="feather"
                      icon="icon-lock"
                      color="success"
                      class="w-full"
                      type="filled"
                      v-if="data.dashboard.rawDataInfo.isShared"
                      >{{ $l.get("dictionary.shared") }}</vs-button
                    >
                    <vs-button
                      disabled
                      icon-pack="feather"
                      icon="icon-lock"
                      color="primary"
                      class="w-full"
                      type="filled"
                      v-else
                      >{{ $l.get("dictionary.private") }}</vs-button
                    >
                  </div>
                  <vs-button
                    color="primary"
                    class="w-full"
                    type="border"
                    :disabled="!isTransformerProfile"
                    icon-pack="feather"
                    :icon="isTransformerProfile ? 'icon-download' : 'icon-lock'"
                    @click="onDownloadClick"
                    >{{ $l.get("overview.download-zip-report") }}</vs-button
                  >
                  <!---->
                </div>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
        <div class="vx-col w-full lg:w-1/4 xl:w-1/4">
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("dictionary.gender") }}</h3>
            </div>
            <vs-row v-if="isMale">
              <vs-col vs-w="1" />
              <vs-col vs-w="5" style="text-align: center">
                <img
                  src="@/application/assets/images/graphics/male.png"
                  style="height: 148px;"
                />
              </vs-col>
              <vs-col vs-w="5" style="text-align: center">
                <h3 style="margin-top: 60px;">
                  {{ $l.get("dictionary.male") }}
                </h3>
              </vs-col>

              <vs-col vs-w="1" />
            </vs-row>
            <vs-row v-if="isFemale">
              <vs-col vs-w="1" />
              <vs-col vs-w="5" style="text-align: center">
                <img
                  src="@/application/assets/images/graphics/female.png"
                  style="height: 148px;"
                />
              </vs-col>
              <vs-col vs-w="5" style="text-align: center">
                <h3 style="margin-top: 60px;">
                  {{ $l.get("dictionary.female") }}
                </h3>
              </vs-col>
              <vs-col vs-w="1" />
            </vs-row>
          </vs-card>
        </div>
      </div>
      <div class="vx-row">
        <div
          class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
          v-for="(reportVariant, index) in variants"
          :key="`${index}-variant`"
        >
          <div class="vx-card">
            <!---->
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div class="vx-card__body">
                <img
                  alt="content-img"
                  class="responsive rounded-lg variant-image"
                  v-if="reportVariant.figure"
                  :src="reportVariant.figure.figure"
                />
                <div class="my-6">
                  <div class="flex justify-between">
                    <h5 class="mb-2" v-if="$l.isPtBr">
                      {{ reportVariant.report.titlePtBr }}
                    </h5>
                    <h5 class="mb-2" v-if="$l.isEnUs">
                      {{ reportVariant.report.titleEnUs }}
                    </h5>
                    <h5 class="mb-2" v-if="$l.isEsEs">
                      {{ reportVariant.report.titleEsEs }}
                    </h5>
                    <div class="mb-2">
                      <ReportReliability
                        :reliability="reportVariant.report.reliability"
                      />
                    </div>
                  </div>
                  <!-- <h5 class="mb-2">Perfil Guerreiro</h5> -->
                </div>
                <div class="variant-description">
                  <p v-if="$l.isPtBr">{{ reportVariant.resultPtBr }}</p>
                  <p v-if="$l.isEnUs">{{ reportVariant.resultEnUs }}</p>
                  <p v-if="$l.isEsEs">{{ reportVariant.resultEsEs }}</p>
                </div>
                <div class="mb-4">
                  <div class="flex justify-between">
                    <small class="font-semibold">
                      {{ $l.get("overview.complete-sampling") }}
                    </small>
                    <small class="text-grey">
                      {{ $l.get("overview.global-report") }}
                    </small>
                  </div>
                  <div
                    class="vs-progress--background block mt-1 shadow-md vs-progress-primary"
                    style="height: 5px;"
                  >
                    <div
                      class="vs-progress--foreground"
                      style="width: 100%;"
                    ></div>
                    <!---->
                  </div>
                </div>
                <div class="flex justify-between flex-wrap">
                  <div></div>
                  <vs-button
                    color="primary"
                    type="border"
                    @click="onLearnMoreClick(reportVariant)"
                    >{{ $l.get("dictionary.learn-more") }}</vs-button
                  >
                </div>
              </div>
              <!---->
            </div>
            <div
              class="vx-card__code-container collapsed"
              style="max-height: 0px; display: none;"
            >
              <div class="code-content">
                <pre
                  class="language-markup"
                ><code class="language-markup"></code></pre>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row" v-if="canIncreaseResultsLimit">
        <div
          class="vx-col w-full mb-base animated infinite pulse"
          style="text-align: center !important"
        >
          <vs-button class="w-1/2" @click="increaseResultsLimit">
            {{ $l.get("overview.more-results") }}
          </vs-button>
        </div>
        <vs-divider />
      </div>

      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <vx-card title="DNA Skills Bank">
            <!-- Chart -->
            <div slot="no-body">
              <vue-apex-charts
                type="radialBar"
                height="420"
                :options="dnaCountsOptions"
                :series="dnaCountsSeries"
              />
            </div>

            <ul>
              <li class="flex mb-3 justify-between">
                <span class="flex items-center">
                  <span
                    class="inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid border-primary"
                  ></span>
                  <span class="font-semibold">Genememosets</span>
                </span>
                <span>{{
                  formatNumber(data.dashboard.dnaCounts.genememosets)
                }}</span>
              </li>
              <li class="flex mb-3 justify-between">
                <span class="flex items-center">
                  <span
                    class="inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid border-warning"
                  ></span>
                  <span class="font-semibold">Genosets</span>
                </span>
                <span>{{
                  formatNumber(data.dashboard.dnaCounts.genosets)
                }}</span>
              </li>
              <li class="flex mb-3 justify-between">
                <span class="flex items-center">
                  <span
                    class="inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid border-danger"
                  ></span>
                  <span class="font-semibold">SNPs</span>
                </span>
                <span>{{ formatNumber(data.dashboard.dnaCounts.snps) }}</span>
              </li>
            </ul>
          </vx-card>
        </div>
        <!-- RADIAL CHART -->
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <vx-card :title="$l.get('overview.reporting-availability')">
            <!-- CHART -->
            <template slot="no-body">
              <div class="mt-10">
                <vue-apex-charts
                  type="radialBar"
                  height="420"
                  :options="reportsCountsOptions"
                  :series="reportsCountsSeries"
                />
              </div>
            </template>

            <!-- DATA -->
            <div
              class="flex justify-between text-center mt-6"
              slot="no-body-bottom"
            >
              <div
                class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0"
              >
                <p class="mt-4">{{ $l.get("dictionary.available") }}</p>
                <p class="mb-4 text-3xl font-semibold">
                  {{ formatNumber(data.dashboard.reportsCounts.available) }}
                </p>
              </div>
              <div
                class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0"
              >
                <p class="mt-4">{{ $l.get("dictionary.blocked") }}</p>
                <p class="mb-4 text-3xl font-semibold">
                  {{ formatNumber(data.dashboard.reportsCounts.locked || 0) }}
                </p>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <!-- <vx-card :title="$l.get('profiles.timeline-title')">
            <ul class="vx-timeline">
              <li>
                <div class="timeline-icon bg-primary">
                  <feather-icon
                    icon="FeatherIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">
                    {{ $l.get("profiles.apprentice-profile") }}
                    <span style="position: relative; bottom: -5px;">
                      <feather-icon
                        icon="CheckIcon"
                        v-if="isApprenticeProfile"
                        svgClasses="text-success"
                      />
                    </span>
                  </p>
                  <span class="activity-desc">
                    {{ $l.get("profiles.apprentice-profile-description") }}
                  </span>
                </div>
                <small
                  v-if="isApprenticeProfile"
                  class="text-success activity-e-time"
                  >{{ $l.get("profiles.profile-active", "upper") }}</small
                >
              </li>
              <li>
                <div class="timeline-icon bg-warning">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">
                    {{ $l.get("profiles.hero-profile") }}
                    <span style="position: relative; bottom: -5px;">
                      <feather-icon
                        icon="CheckIcon"
                        v-if="isHeroProfile"
                        svgClasses="text-success"
                      />
                    </span>
                  </p>
                  <span class="activity-desc">
                    {{ $l.get("profiles.hero-profile-description") }}
                  </span>
                  <div v-if="!isHeroProfile">
                    <br />
                    <vs-button color="success" @click="onUpgradeClick">{{
                      $l.get("profiles.profile-activation-action")
                    }}</vs-button>
                  </div>
                </div>
                <small
                  v-if="isHeroProfile"
                  class="text-success activity-e-time"
                  >{{ $l.get("profiles.profile-active", "upper") }}</small
                >
                <small
                  v-else-if="!isTransformerProfile"
                  class="text-grey activity-e-time"
                  >{{ $l.get("profiles.profile-blocked", "upper") }}</small
                >
              </li>
              <li>
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="HeartIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">
                    {{ $l.get("profiles.transformer-profile") }}
                  </p>
                  <span class="activity-desc">
                    {{ $l.get("profiles.transformer-profile-description") }}
                  </span>
                  <div v-if="!isTransformerProfile">
                    <br />
                    <vs-button disabled color="success">{{
                      $l.get("profiles.profile-activation-action")
                    }}</vs-button>
                  </div>
                </div>
                <small
                  v-if="isTransformerProfile"
                  class="text-success activity-e-time"
                  >{{ $l.get("profiles.profile-active", "upper") }}</small
                >
                <small v-else class="text-grey activity-e-time">{{
                  $l.get("profiles.profile-blocked", "upper")
                }}</small>
              </li>
            </ul>
          </vx-card>-->
          <vx-card
            :title="$l.get('covid19.header')"
            v-if="data && data.customDna"
          >
            <ul class="vx-timeline">
              <!-- <p v-if="data.customDna.length == 0">{{ $l.get("covid19.empty") }}</p> -->
              <li v-if="data.customDna.length == 0">
                <div class="timeline-icon bg-primary">
                  <feather-icon
                    icon="CoffeeIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">{{ $l.get("covid19.empty") }}</p>
                  <span class="activity-desc">{{
                    $l.get("covid19.empty-desc")
                  }}</span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '664fe831-bce4-4a66-a343-6f70724fdcc7'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Gs110</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.ashtma") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '928c8c51-b8a8-4b29-aae4-51a1d04a38a7'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Gs121</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.diabetes1") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '7b314a02-10a9-4729-891b-281dcd7ae6b6'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Gs197</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.diabetes2") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '93d0d99b-9430-4a7d-b34b-7540f2cf7291'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Gs111</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.coronary-heart-disease") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '344a8117-8c07-4fc3-a13f-de263fbe1188'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Gs132</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.blood-type-a") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '32d1b55d-a9f4-447b-8695-57fee7d102b8'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Gs289</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.serotonin") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    'a2e9e1b0-9f13-4e9c-969f-e16bf5580d14'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Rs1121980(T;T)</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.obesity") }}.
                  </span>
                </div>
              </li>
              <li
                v-if="
                  data.customDna.indexOf(
                    '0335eae1-3965-46b6-a1d2-b38b9feaf272'
                  ) >= 0
                "
              >
                <div class="timeline-icon bg-danger">
                  <feather-icon
                    icon="ActivityIcon"
                    svgClasses="text-white stroke-current w-5 h-5"
                  />
                </div>
                <div class="timeline-info">
                  <p class="font-semibold">Rs4293393(T;T)</p>
                  <span class="activity-desc">
                    {{ $l.get("covid19.risk-of") }}
                    {{ $l.get("covid19.chronic-kidney-desease") }}.
                  </span>
                </div>
              </li>
            </ul>
          </vx-card>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <vx-card
            :title="$l.get('platinum-soccer.header')"
            v-if="data && data.soccerResults"
          >
            <div slot="no-body" v-if="containsSoccerResults">
              <vue-apex-charts
                type="radialBar"
                height="420"
                :options="soccerResultsOptions"
                :series="soccerResultsSeries"
              />
            </div>
            <div v-else style="text-align: center">
              <p>Os resultados estão sendo processados...</p>
              <br />
              <vs-button
                color="primary"
                class="w-full"
                type="filled"
                icon-pack="feather"
                icon="icon-refresh-cw"
                :disabled="isLoadingSoccerResults"
                @click="onSoccerResultsUpdateClick"
                >{{ $l.get("dictionary.refresh") }}</vs-button
              >
            </div>
          </vx-card>
        </div>
      </div>
      <div class="vx-col w-full mb-base" v-if="data">
        <vx-card :title="$l.get('profiles.reports-available')">
          <vs-table
            search
            max-items="5"
            pagination
            :data="data.dashboard.reportsInfos"
          >
            <template slot="thead">
              <vs-th>{{ $l.get("dictionary.feature", "upper") }}</vs-th>
              <vs-th>{{ $l.get("dictionary.status", "upper") }}</vs-th>
              <vs-th>{{ $l.get("dictionary.description", "upper") }}</vs-th>
              <vs-th>{{ $l.get("dictionary.precision", "upper") }}</vs-th>
              <vs-th>{{ $l.get("dictionary.type", "upper") }}</vs-th>
              <vs-th>{{ $l.get("dictionary.updated", "upper") }}</vs-th>
              <vs-th>{{ $l.get("dictionary.learn-more", "upper") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <!-- TITLE -->
                <vs-td :data="tr.titleEnUs" v-if="$l.isEnUs">{{
                  tr.titleEnUs
                }}</vs-td>
                <vs-td :data="tr.titleEsEs" v-if="$l.isEsEs">{{
                  tr.titleEsEs
                }}</vs-td>
                <vs-td :data="tr.titlePtBr" v-if="$l.isPtBr">{{
                  tr.titlePtBr
                }}</vs-td>

                <!-- STATUS -->
                <vs-td :data="1" v-if="isReportAvailable(tr)">
                  <span class="flex items-center px-2 py-1 rounded">
                    <div class="h-3 w-3 rounded-full mr-2 bg-success"></div>
                    {{ $l.get("dictionary.available") }}
                  </span>
                </vs-td>
                <vs-td :data="2" v-else-if="isReportHero(tr)">
                  <span class="flex items-center px-2 py-1 rounded">
                    <div class="h-3 w-3 rounded-full mr-2 bg-danger"></div>
                    {{ $l.get("profiles.hero-profile") }}
                  </span>
                </vs-td>
                <vs-td :data="3" v-else-if="isReportTransformer(tr)">
                  <span class="flex items-center px-2 py-1 rounded">
                    <div class="h-3 w-3 rounded-full mr-2 bg-danger"></div>
                    {{ $l.get("profiles.transformer-profile") }}
                  </span>
                </vs-td>
                <vs-td :data="4" v-else>
                  <span class="flex items-center px-2 py-1 rounded">
                    <div class="h-3 w-3 rounded-full mr-2 bg-danger"></div>
                    {{ $l.get("dictionary.unavailable") }}
                  </span>
                </vs-td>

                <!-- SUBTITLE -->
                <vs-td :data="tr.subtitleEnUs" v-if="$l.isEnUs">{{
                  tr.subtitleEnUs
                }}</vs-td>
                <vs-td :data="tr.subtitleEsEs" v-if="$l.isEsEs">{{
                  tr.subtitleEsEs
                }}</vs-td>
                <vs-td :data="tr.subtitlePtBr" v-if="$l.isPtBr">{{
                  tr.subtitlePtBr
                }}</vs-td>

                <vs-td>
                  <ReportReliability :reliability="tr.reliability" />
                </vs-td>

                <vs-td>
                  <vs-chip
                    v-if="tr.variants.filter(e => e[0] != null).length > 0"
                    >{{ $l.get("dictionary.genotype") }}</vs-chip
                  >
                  <vs-chip
                    v-if="tr.variants.filter(e => e[1] != null).length > 0"
                    >{{ $l.get("dictionary.genoset") }}</vs-chip
                  >
                </vs-td>

                <vs-td :data="tr.updatedAt">{{ tr.updatedAt }}</vs-td>
                <vs-td>
                  <vs-button
                    icon-pack="feather"
                    icon="icon-eye"
                    type="border"
                    @click="onSelectAvailableReport(tr)"
                  ></vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
    <div
      style="text-align: center; color: #AAAAAA !important"
      v-if="showWaitingResults"
    >
      <feather-icon
        icon="ClockIcon"
        style="margin-top: 50px; margin-bottom: 10px"
      />
      <h3 style="color: #AAAAAA !important">
        {{ $l.get("overview.waiting-results") }}
      </h3>
    </div>
    <div
      style="text-align: center; color: #AAAAAA !important"
      v-if="showWaitingDownload"
    >
      <feather-icon
        icon="DownloadCloudIcon"
        style="margin-top: 50px; margin-bottom: 10px"
      />
      <h3 style="color: #AAAAAA !important; margin-bottom: 20px">
        {{ $l.get("overview.waiting-download") }}
      </h3>
      <p style="color: #AAAAAA !important; margin-bottom: 20px">
        {{ $l.get("overview.keep-page-open") }}
      </p>
      <vs-button
        color="primary"
        type="border"
        @click="onCloseDownloadClick()"
        >{{ $l.get("dictionary.close") }}</vs-button
      >
    </div>
    <div
      style="text-align: center; color: #AAAAAA !important"
      v-if="showRawDataFailed"
    >
      <feather-icon
        icon="AlertTriangleIcon"
        style="margin-top: 50px; margin-bottom: 10px"
      />
      <h3 style="color: #AAAAAA !important; margin-bottom: 20px">
        {{ $l.get("overview.raw-data-failed") }}
      </h3>
      <vs-upload
        :action="uploadAddress"
        fileName="raw"
        :headers="data.session.tokens.getHeader()"
        :limit="1"
        :show-upload-button="true"
        :automatic="true"
        accept=".txt, .csv, .zip"
        :text="$l.get('welcome.upload-area', 'upper')"
        @on-success="onUploadFinished"
        @on-fail="onUploadFinished"
      />
    </div>
  </div>
</template>

<script>
import SelectUpdateSoccerResultsInteractor from "@/business/overview/select-update-soccer-results";
import UpdateRawDataInteractor from "@/business/overview/update-raw-data";
import SelectAvailableReportInteractor from "@/business/overview/select-available-report";
import VueApexCharts from "vue-apexcharts";

import SelectCloseDownloadInteractor from "@/business/overview/select-close-download";
import SelectAcceptRawDataUpgradeInteractor from "@/business/overview/select-accept-raw-data-upgrade";
import SelectDownloadFullReport from "@/business/overview/select-download-full-report";
import SelectResultLearnMoreInteractor from "@/business/overview/select-result-learn-more";
import InitOverviewScreenInteractor from "@/business/overview/init-overview-screen";
import OverviewScreenController from "@/adapters/controllers/screen-overview";
import Loader from "@/application/components/Loader";
import ReportReliability from "@/application/components/ReportReliability";

export default {
  screen_name: "overview",
  data() {
    const _ = this;
    return {
      controller: null,
      interactors: {
        selectUpdateSoccerResults: null,
        updateRawData: null,
        selectAvailableReport: null,
        selectCloseDownload: null,
        selectDownloadFullReport: null,
        selectAcceptRawDataUpgrade: null,
        selectResultLearnMore: null,
        initOverviewScreen: null
      },
      dnaCountsOptions: {
        labels: ["Genememosets", "Genosets", "SNPs"],
        plotOptions: {
          radialBar: {
            size: 165,
            offsetY: -5,
            hollow: {
              size: "20%"
            },
            track: {
              background: "#ebebeb",
              strokeWidth: "100%",
              margin: 15
            },
            dataLabels: {
              show: true,
              name: {
                fontSize: "18px"
              },
              value: {
                fontSize: "16px",
                color: "#636a71",
                offsetY: 11
              },
              total: {
                show: true,
                label: "Total",
                formatter() {
                  const series = _.dnaCountsSeriesTotal;
                  return series[0] + series[1] + series[2];
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 576,
            options: {
              plotOptions: {
                radialBar: {
                  size: 150,
                  hollow: {
                    size: "20%"
                  },
                  track: {
                    background: "#ebebeb",
                    strokeWidth: "100%",
                    margin: 15
                  }
                }
              }
            }
          }
        ],
        colors: ["#7961F9", "#FF9F43", "#EA5455"],
        fill: {
          type: "gradient",
          gradient: {
            // enabled: true,
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#9c8cfc", "#FFC085", "#f29292"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        chart: {
          height: 355,
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      },
      soccerResultsOptions: {
        labels: ["Bronze", "Prata", "Ouro"],
        plotOptions: {
          radialBar: {
            size: 165,
            offsetY: -5,
            hollow: {
              size: "20%"
            },
            track: {
              background: "#ebebeb",
              strokeWidth: "100%",
              margin: 15
            },
            dataLabels: {
              show: true,
              name: {
                fontSize: "18px"
              },
              value: {
                fontSize: "16px",
                color: "#636a71",
                offsetY: 11
              },
              total: {
                show: true,
                label: "Controle",
                formatter() {
                  return _.soccerResultsControl;
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 576,
            options: {
              plotOptions: {
                radialBar: {
                  size: 150,
                  hollow: {
                    size: "20%"
                  },
                  track: {
                    background: "#ebebeb",
                    strokeWidth: "100%",
                    margin: 15
                  }
                }
              }
            }
          }
        ],
        // colors: ["#7961F9", "#FF9F43", "#EA5455"],
        colors: ["#CD7F32", "#C0C0C0", "#FFD700"],
        fill: {
          type: "gradient",
          gradient: {
            // enabled: true,
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#CD9932", "#C5C5C5", "#FDFF00"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        chart: {
          height: 355,
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      },
      reportsCountsOptions: {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%"
            },
            track: {
              background: "#bfc5cc",
              strokeWidth: "50%"
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 18,
                color: "#99a2ac",
                fontSize: "4rem"
              }
            }
          }
        },
        colors: ["#00db89"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#00b5b5"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        chart: {
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      },
      rawDataGateway: null,
      data: null,
      resultsLimit: 6,
      isLoading: false,
      isLoadingSoccerResults: false
    };
  },
  components: { Loader, VueApexCharts, ReportReliability },
  beforeMount() {
    this.controller = this.$injector.get(OverviewScreenController);
    this.rawDataGateway = this.$injector.get("RawDataGateway");

    //{ INTERACTORS
    this.interactors.initOverviewScreen = this.$injector.get(
      InitOverviewScreenInteractor
    );
    this.interactors.selectResultLearnMore = this.$injector.get(
      SelectResultLearnMoreInteractor
    );
    this.interactors.selectAcceptRawDataUpgrade = this.$injector.get(
      SelectAcceptRawDataUpgradeInteractor
    );
    this.interactors.selectDownloadFullReport = this.$injector.get(
      SelectDownloadFullReport
    );
    this.interactors.selectCloseDownload = this.$injector.get(
      SelectCloseDownloadInteractor
    );
    this.interactors.selectAvailableReport = this.$injector.get(
      SelectAvailableReportInteractor
    );
    this.interactors.updateRawData = this.$injector.get(
      UpdateRawDataInteractor
    );
    this.interactors.selectUpdateSoccerResults = this.$injector.get(
      SelectUpdateSoccerResultsInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initOverviewScreen.handle();
  },
  computed: {
    uploadAddress() {
      if (this.data && this.data.session && this.data.session.user) {
        return `${this.rawDataGateway.BASE_URL}/raw-data-update/?child=${this.data.session.user.id}`;
      } else {
        return `${this.rawDataGateway.BASE_URL}/raw-data-update/`;
      }
    },
    showIsLoading() {
      return this.isLoading;
    },
    showDashboard() {
      return (
        this.data &&
        !this.data.isRawDataFailed &&
        this.data.dashboard &&
        this.data.check &&
        this.data.check.isRawDataProcessed &&
        !this.data.isWaitingDownload &&
        !this.isLoading
      );
    },
    showWaitingResults() {
      return (
        this.data &&
        !this.data.isRawDataFailed &&
        !(
          this.data.dashboard &&
          this.data.check &&
          this.data.check.isRawDataProcessed
        ) &&
        !this.data.isWaitingDownload &&
        !this.isLoading
      );
    },
    showWaitingDownload() {
      return (
        this.data &&
        !this.data.isRawDataFailed &&
        this.data.dashboard &&
        this.data.check &&
        this.data.check.isRawDataProcessed &&
        this.data.isWaitingDownload &&
        !this.isLoading
      );
    },
    showRawDataFailed() {
      return this.data && this.data.isRawDataFailed && !this.isLoading;
    },
    isApprenticeProfile() {
      return (
        this.data != null &&
        this.data.session != null &&
        this.data.session.user.plan != null &&
        this.data.session.user.plan.plan != null &&
        this.data.session.user.plan.plan.isApprentice
      );
    },
    isHeroProfile() {
      return (
        this.data != null &&
        this.data.session != null &&
        this.data.session.user.plan != null &&
        this.data.session.user.plan.plan != null &&
        this.data.session.user.plan.plan.isHero
      );
    },
    isTransformerProfile() {
      return (
        this.data != null &&
        this.data.session != null &&
        this.data.session.user.plan != null &&
        this.data.session.user.plan.plan != null &&
        this.data.session.user.plan.plan.isTransformer
      );
    },
    canDownloadReport() {
      return (
        this.data != null &&
        this.data.session != null &&
        this.data.session.user.plan != null &&
        this.data.session.user.plan.plan != null &&
        this.data.session.user.plan.plan.paymentType != 1
      );
    },
    dnaCountsSeriesTotal() {
      if (this.data && this.data.dashboard) {
        return [
          this.data.dashboard.dnaCounts.genememosets,
          this.data.dashboard.dnaCounts.genosets,
          this.data.dashboard.dnaCounts.snps
        ];
      } else {
        return [0, 0, 0];
      }
    },
    dnaCountsSeries() {
      if (this.data && this.data.dashboard) {
        const totals = this.dnaCountsSeriesTotal;
        const total = totals[0] + totals[1] + totals[2];
        if (total == 0) return [0, 0, 0];
        return [
          ((100 * this.data.dashboard.dnaCounts.genememosets) / total).toFixed(
            2
          ),
          ((100 * this.data.dashboard.dnaCounts.genosets) / total).toFixed(2),
          ((100 * this.data.dashboard.dnaCounts.snps) / total).toFixed(2)
        ];
      } else {
        return [0, 0, 0];
      }
    },
    containsSoccerResults() {
      return (
        this.data &&
        this.data.soccerResults &&
        this.data.soccerResults.resultData &&
        this.data.soccerResults.resultData.groups != null
      );
    },
    soccerResultsControl() {
      if (this.containsSoccerResults) {
        return `${this.data.soccerResults.resultData.control}%`;
      } else {
        return `0%`;
      }
    },
    soccerResultsSeries() {
      if (this.containsSoccerResults) {
        return [
          this.data.soccerResults.resultData.groups[0].result,
          this.data.soccerResults.resultData.groups[1].result,
          this.data.soccerResults.resultData.groups[2].result
        ];
      } else {
        return [0, 0, 0];
      }
    },
    reportsCountsSeries() {
      if (this.data && this.data.dashboard) {
        const total =
          this.data.dashboard.reportsCounts.available +
          this.data.dashboard.reportsCounts.locked;
        if (total == 0) return [0];
        return [
          ((100 * this.data.dashboard.reportsCounts.available) / total).toFixed(
            2
          )
        ];
      } else {
        return [0];
      }
    },
    variants() {
      const variants = [];
      if (this.data && this.data.reportVariants) {
        for (const variant of this.data.reportVariants) {
          if (!this.isGenderVariant(variant)) {
            variants.push(variant);
          }
          if (variants.length >= this.resultsLimit) {
            break;
          }
        }
      }
      return variants;
    },
    genderVariant() {
      if (this.data && this.data.reportVariants) {
        for (const variant of this.data.reportVariants) {
          if (this.isGenderVariant(variant)) {
            return variant;
          }
        }
      }
      return null;
    },
    isMale() {
      return (
        this.data && this.data.dashboard && this.data.dashboard.gender == 1
      );
    },
    isFemale() {
      return (
        this.data && this.data.dashboard && this.data.dashboard.gender == 2
      );
    },
    canIncreaseResultsLimit() {
      return (
        this.data &&
        this.data.reportVariants &&
        this.resultsLimit < this.data.reportVariants.length
      );
    }
  },
  methods: {
    increaseResultsLimit() {
      this.resultsLimit += 6;
    },
    setData(data) {
      this.data = data;
      if (data && data.dashboard) {
        this.reportsInfos = data.dashboard.reportsInfos;
      }
      this.$forceUpdate();
    },
    isGenderVariant(variant) {
      return (
        variant &&
        variant.report &&
        variant.report.id == "76b03865-4b28-4427-b9ba-fdcc1487cc0b"
      );
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    async onSoccerResultsUpdateClick() {
      this.isLoadingSoccerResults = true;
      await this.interactors.selectUpdateSoccerResults.handle();
      this.isLoadingSoccerResults = false;
    },
    formatNumber(num) {
      if (num || num == 0) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return "";
      }
    },
    onUpgradeClick() {
      this.$vs.dialog({
        color: "primary",
        acceptText: this.$l.get("dictionary.upgrade"),
        title: this.$l.get("overview.free-upgrade-title"),
        text: this.$l.get("overview.free-upgrade-body"),
        accept: () => {
          this.interactors.selectAcceptRawDataUpgrade.handle();
        }
      });
    },
    onDownloadClick() {
      this.interactors.selectDownloadFullReport.handle();
    },
    onLearnMoreClick(reportVariant) {
      this.interactors.selectResultLearnMore.handle(reportVariant);
    },
    onCloseDownloadClick() {
      this.interactors.selectCloseDownload.handle();
    },
    isReportAvailable(report) {
      return (
        report.permissionType == 1 ||
        report.plans.length == 0 ||
        (this.data.session &&
          report.plans.filter(
            e =>
              e[0] == 1 && e[1] <= this.data.session.user.plan.plan.paymentType
          ).length > 0)
      );
    },
    isReportHero(report) {
      return (
        this.data.session &&
        report.permissionType == 2 &&
        report.plans.length > 0 &&
        report.plans.filter(
          e =>
            e[0] == 1 &&
            e[1] == 2 &&
            e[1] > this.data.session.user.plan.plan.paymentType
        ).length > 0
      );
    },
    isReportTransformer(report) {
      return (
        this.data.session &&
        report.permissionType == 2 &&
        report.plans.length > 0 &&
        report.plans.filter(
          e =>
            e[0] == 1 &&
            e[1] >= 3 &&
            e[1] > this.data.session.user.plan.plan.paymentType
        ).length > 0
      );
    },
    onUploadFinished() {
      this.interactors.initOverviewScreen.handle(true);
    },
    onSelectAvailableReport(report) {
      this.interactors.selectAvailableReport.handle(report.id);
    }
  }
};
</script>

<style lang="scss">
@import "@/application/assets/scss/vuexy/components/vxTimeline.scss";

#overview {
  .variant-image {
    height: 300px;
    object-fit: cover;
  }

  .variant-description {
    color: #999999;
    margin-top: -10px;
    margin-bottom: 15px;
  }

  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 300px;
      min-height: 300px;
    }
  }
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>

<style lang="scss" scoped>
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
</style>

<style lang="scss">
.con-img-upload .con-input-upload {
  width: 100% !important;
  margin: 5px;
  // background: rgba(var(--vs-primary), 0.15) !important;
  box-shadow: 0px 5px 5px rgba(var(--vs-primary), 0.5);
}
.con-img-upload .con-input-upload:hover {
  box-shadow: 0px 10px 5px rgba(var(--vs-primary), 0.5);
}
.con-img-upload .con-input-upload:active {
  box-shadow: 0px 1px 5px rgba(var(--vs-primary), 0.5);
}

.con-img-upload .text-input {
  font-size: 12px;
  margin-top: -24px !important;
}

.con-img-upload .img-upload {
  display: none;
}
.con-img-upload .on-progress-all-upload {
  opacity: 1 !important;
}
.con-img-upload .disabled-upload {
  height: 4px !important;
}

.reports-available-row {
  cursor: pointer;
}
</style>
