<template>
  <div class="report-reliability-component">
    <div v-if="isLow">
      <feather-icon
        icon="StarIcon"
        svgClasses="fill-current text-warning h-4 w-4"
      />
    </div>
    <div v-else-if="isMid">
      <feather-icon
        icon="StarIcon"
        svgClasses="fill-current text-warning h-4 w-4"
      />
      <feather-icon
        icon="StarIcon"
        svgClasses="fill-current text-warning h-4 w-4"
      />
    </div>
    <div v-else-if="isHigh">
      <feather-icon
        icon="StarIcon"
        svgClasses="fill-current text-warning h-4 w-4"
      />
      <feather-icon
        icon="StarIcon"
        svgClasses="fill-current text-warning h-4 w-4"
      />
      <feather-icon
        icon="StarIcon"
        svgClasses="fill-current text-warning h-4 w-4"
      />
    </div>
    <div v-else>
      <feather-icon icon="StarIcon" svgClasses="text-warning h-4 w-4" />
    </div>
  </div>
</template>

<script>
export default {
  name: "report-reliability",
  props: ["reliability"],
  computed: {
    isUndefined() {
      return this.reliability == 0;
    },
    isLow() {
      return !this.isUndefined && this.reliability < 45;
    },
    isMid() {
      return !this.isLow && this.reliability < 90;
    },
    isHigh() {
      return this.reliability >= 90;
    }
  }
};
</script>
